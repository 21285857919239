<div
    class="absolute inset-0 min-w-0 overflow-auto text-center print:overflow-visible"
    cdkScrollable
>
    <!-- Main -->
    <div class="inline-block p-4 sm:p-10 text-left print:p-0 mt-4 sm:mt-10">
        <!-- Invoice -->
        <div
            class="bg-card w-full sm:w-240 min-w-0 sm:min-w-240 rounded-2xl shadow print:w-auto print:rounded-none print:bg-transparent print:p-0 print:shadow-none"
            *ngIf="!this.loading"
        >
            <div
                class="text-2xl text-white font-bold text-center p-2 rounded-t-2xl"
                [ngClass]="{
                    'invoice-paid': invoice.status === InvoiceStatus.Paid,
                    'invoice-waiting': invoice.status === InvoiceStatus.WaitingPayment,
                    'invoice-unpaid': invoice.status === InvoiceStatus.Unpaid
                }"
            >
                {{ invoice.statusDescription }}
            </div>

            <div class="p-4 sm:p-16">
                <div class="flex flex-col sm:flex-row items-start">
                    <div class="grid grid-rows-2 place-items-start gap-y-4 sm:gap-y-12">
                        <div class="grid auto-cols-max grid-flow-col gap-x-4 sm:gap-x-8">
                            <div class="w-16 sm:w-24 place-self-center">
                                <img
                                    height="109"
                                    src="https://aliancaamerica.com.br/wp-content/uploads/2022/01/alianca-logo-color.svg"
                                />
                            </div>
                            <div class="border-l pl-4 sm:pl-10 text-sm sm:text-md">
                                <div class="font-medium">Aliança america.</div>
                                <div>CNPJ 23.800.572/0001-50</div>
                                <div>ENDEREÇO</div>
                                <div>+66 123 455 87</div>
                                <div>financeiro&#64;aliancaamerica.com.br</div>
                                <div>www.aliancaamerica.com.br</div>
                            </div>
                        </div>
                        <div class="grid auto-cols-max grid-flow-col gap-x-4 sm:gap-x-8">
                            <div
                                class="text-secondary w-16 sm:w-24 place-self-center text-center text-lg sm:text-2xl"
                            >
                                Aluno
                            </div>
                            <div class="border-l pl-4 sm:pl-10 text-sm sm:text-md">
                                <div class="font-medium"> {{ invoice.studentName }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="ml-auto grid grid-cols-2 gap-x-2 sm:gap-x-4 gap-y-1 text-sm sm:text-base">
                        <div
                            class="text-secondary justify-self-end text-2xl sm:text-4xl tracking-tight"
                        >
                            Fatura
                        </div>
                        <div class="text-2xl sm:text-4xl">#{{ invoice.invoiceId }}</div>
                        <div
                            class="text-secondary justify-self-end font-medium tracking-tight"
                        >
                            DATA
                        </div>
                        <div class="font-medium">{{ invoice.createdAt | date: "dd/MM/yyyy" }}</div>
                        <div
                            class="text-secondary justify-self-end font-medium tracking-tight"
                        >
                            VENCIMENTO
                        </div>
                        <div class="font-medium">{{ invoice.dueAt | date: "dd/MM/yyyy" }}</div>
                    </div>
                </div>

                <div class="mt-8 sm:mt-16 grid grid-cols-12 gap-x-1">
                    <!-- Columns -->
                    <div class="text-secondary col-span-8 sm:col-span-10 text-sm sm:text-md font-medium">
                        Serviço
                    </div>
                    <div class="text-secondary text-right col-span-4 sm:col-span-2 text-sm sm:text-md font-medium">
                        VALOR
                    </div>
                    <div class="col-span-12 my-2 sm:my-4 border-b"></div>

                    <div class="col-span-8 sm:col-span-10">
                        <div class="text-base sm:text-lg font-medium">Mensalidade</div>
                        <div class="text-secondary mt-1 sm:mt-2 text-sm sm:text-md">
                            {{ invoice.description }}
                        </div>
                    </div>
                    <div class="self-center text-right col-span-4 sm:col-span-2">{{ invoice.amount | currency: 'BRL' }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-2 sm:my-4 border-b"></div>

                    <!-- Spacer -->
                    <div class="col-span-12 mt-8 sm:mt-16"></div>

                    <!-- Subtotal -->
                    <div
                        class="text-secondary col-span-8 sm:col-span-10 self-center font-medium tracking-tight"
                    >
                        SUBTOTAL
                    </div>
                    <div class="col-span-4 sm:col-span-2 text-right text-base sm:text-lg">{{ invoice.amount | currency: 'BRL' }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-2 sm:my-3 border-b"></div>

                    <!-- Tax -->
                    <div
                        class="text-secondary col-span-8 sm:col-span-10 self-center font-medium tracking-tight"
                        *ngIf="invoice.hasTax"
                    >
                        JUROS
                    </div>
                    <div class="col-span-4 sm:col-span-2 text-right text-base sm:text-lg" *ngIf="invoice.hasTax">
                        {{ invoice.tax | currency: 'BRL' }}
                    </div>

                    <div class="col-span-12 my-2 sm:my-3 border-b" *ngIf="invoice.hasTax"></div>

                    <div
                        class="text-secondary col-span-8 sm:col-span-10 self-center text-lg sm:text-2xl font-medium tracking-tight"
                    >
                        TOTAL
                    </div>
                    <div class="col-span-4 sm:col-span-2 text-right text-lg sm:text-2xl font-medium">
                        {{ invoice.totalAmount | currency: 'BRL' }}
                    </div>
                </div>

                <div class="mt-8 sm:mt-16" *ngIf="invoice.status !== InvoiceStatus.Paid">
                    <div class="font-medium">
                        <div class="mt-4 sm:mt-10">
                            Selecione uma forma de pagamento
                        </div>
                        <div class="w-full flex flex-col sm:flex-row gap-4">
                            <div
                                class="my-3 border flex items-center justify-center gap-6 rounded-2xl cursor-pointer p-5"
                                (click)="selectPaymentMethod(paymentType.PIX)"
                                [ngClass]="this.selectedPaymentType === paymentType.PIX ? 'bg-primary text-black' : 'bg-white'"
                            >
                                <div>
                                    <img
                                        src="assets/icons/icons8-foto.svg"
                                        alt=""
                                        width="50px"
                                        height="50px"
                                        style="width: 30px; height: 30px;"
                                    />
                                </div>
                                <div>
                                    PIX
                                </div>
                            </div>
                            <div
                                class="my-3 border flex items-center justify-center gap-6 rounded-2xl cursor-pointer p-5"
                                (click)="selectPaymentMethod(paymentType.Boleto)"
                                [ngClass]="this.selectedPaymentType === paymentType.Boleto ? 'bg-primary text-black' : 'bg-white'"
                                *ngIf="this.invoice.type != invoiceType.FirstPayment"
                            >
                                <div>
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_outline:document-text'"
                                        [ngClass]="this.selectedPaymentType === paymentType.Boleto ? 'text-black' : 'text-black'"
                                    ></mat-icon>
                                </div>
                                <div>
                                    Boleto
                                </div>
                            </div>
                            <div
                                *ngIf="false"
                                class="my-3 border flex items-center justify-center gap-4 rounded-2xl cursor-pointer p-5"
                                (click)="selectPaymentMethod(paymentType.CreditCard)"
                                [ngClass]="this.selectedPaymentType === paymentType.CreditCard ? 'bg-primary text-white' : 'bg-white'"
                            >
                                <div>
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_outline:credit-card'"
                                        [ngClass]="this.selectedPaymentType === paymentType.CreditCard ? 'text-white' : 'text-black'"
                                    ></mat-icon>
                                </div>
                                <div>
                                    Cartão de crédito
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-payments">
                        <div *ngIf="this.selectedPaymentType === paymentType.PIX">
                            <div *ngIf="pix == null">
                                <button
                                    mat-raised-button
                                    [disabled]="buttonDisabled"
                                    color="primary"
                                    (click)="generatePayment()"
                                    class="bg-primary text-white rounded-2xl p-2 mt-4 w-full"
                                >
                                    <p *ngIf="buttonDisabled">Processando...</p>
                                    <p *ngIf="!buttonDisabled">Gerar PIX</p>
                                </button>
                            </div>

                            <div *ngIf="pix != null">
                                <div class="flex justify-center w-full">
                                    <img
                                        [src]="pix.base64"
                                        alt="QR Code"
                                        style="width: 300px; height: 300px;"
                                    />
                                </div>

                                <div class="flex flex-col items-center justify-center mt-4 w-full">
                                    <mat-form-field class="w-8/12 fuse-mat-dense">
                                        <mat-label>{{ 'Chave PIX' | transloco }}</mat-label>
                                        <input matInput readonly="true" [value]="this.pix.copyAndPaste" />
                                    </mat-form-field>

                                    <br />
                                    <button
                                        mat-button
                                        color="primary"
                                        (click)="copyLink(this.pix.copyAndPaste)"
                                        class="flex justify-center items-center"
                                    >
                                        Copiar<mat-icon
                                            class="icon-size-5 mr-3"
                                            [svgIcon]="'heroicons_outline:clipboard-document-list'"
                                        ></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.selectedPaymentType === paymentType.Boleto">
                            <button
                                mat-raised-button
                                color="primary"
                                [disabled]="buttonDisabled"
                                (click)="generatePayment()"
                                *ngIf="boleto == null"
                                class="bg-primary text-white rounded-2xl p-2 mt-4 w-full"
                            >
                                <p *ngIf="buttonDisabled">Processando...</p>
                                <p *ngIf="!buttonDisabled">Gerar boleto</p>
                            </button>

                            <div *ngIf="boleto != null">
                                <div class="flex items-center justify-center mt-4 w-full">
                                    <mat-form-field class="w-8/12 fuse-mat-dense">
                                        <mat-label>{{ 'Link de pagamento boleto' | transloco }}</mat-label>
                                        <input matInput readonly="true" [value]="this.boleto.url" />
                                    </mat-form-field>
                                    <button mat-button color="primary" (click)="copyLink(this.boleto.url)">
                                        <mat-icon
                                            class="icon-size-5 mr-3"
                                            [svgIcon]="'heroicons_outline:clipboard-document-list'"
                                        ></mat-icon>
                                    </button>
                                </div>

                                <div class="flex items-center justify-center mt-4 w-full">
                                    <mat-form-field class="w-8/12 fuse-mat-dense">
                                        <mat-label>{{ 'Código de barras' | transloco }}</mat-label>
                                        <input matInput readonly="true" [value]="this.boleto.barCode" />
                                    </mat-form-field>
                                    <button mat-button color="primary" (click)="copyLink(this.boleto.barCode)">
                                        <mat-icon
                                            class="icon-size-5 mr-3"
                                            [svgIcon]="'heroicons_outline:clipboard-document-list'"
                                        ></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.selectedPaymentType === paymentType.CreditCard">
                            <!-- <button (click)="generatePayment()" class="bg-primary text-white rounded-2xl p-2 mt-4">FAZER formuario</button> -->

                            <div>
                                <form>
                                    <div class="grid grid-cols-1 gap-4 mt-4">
                                        <mat-form-field class="w-full fuse-mat-dense">
                                            <mat-label>{{ 'Nome no cartão' | transloco }}</mat-label>
                                            <input matInput />
                                        </mat-form-field>
                                        <mat-form-field class="w-full fuse-mat-dense">
                                            <mat-label>{{ 'Número do cartão' | transloco }}</mat-label>
                                            <input matInput type="number" />
                                        </mat-form-field>
                                        <mat-form-field class="w-full fuse-mat-dense">
                                            <mat-label>{{ 'Data' | transloco }}</mat-label>
                                            <input matInput type="number" />
                                        </mat-form-field>
                                        <mat-form-field class="w-full fuse-mat-dense">
                                            <mat-label>{{ 'CVV' | transloco }}</mat-label>
                                            <input matInput maxlength="3" type="number" [maxLength]="3" />
                                        </mat-form-field>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
