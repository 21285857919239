import { ContractPackageCreateCommand } from './../../../../api-client';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Permission } from '../../../../api-client';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: "Dashboards",
    type: 'group',
    children: [
      {
        id: 'dashboards-dash-home',
        title: 'Início',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/start',
      }
    ]
  },
  {
    id: 'leads',
    title: "Comercial",
    type: 'group',
    permission: [Permission.LeadList, Permission.LeadListSelf, Permission.SellerList, Permission.ExperimentalClassListSelf, Permission.ExperimentalClassList, Permission.LeadEdit],
    children: [
      {
        id: 'leads-kanban',
        title: 'Kanban',
        type: 'basic',
        icon: 'heroicons_outline:squares-2x2',
        link: '/leads/kanban',
        permission: [Permission.LeadList, Permission.LeadListSelf]
      },
      {
        id: 'leads-list',
        title: 'Leads',
        type: 'basic',
        icon: 'heroicons_outline:funnel',
        link: '/leads/list',
        permission: [Permission.LeadList, Permission.LeadListSelf]
      },
      {
        id: 'leads-not-paid-list',
        title: 'Aguardando Pagamento',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/leads/list-notpaid',
        permission: [Permission.StudentList, Permission.StudentListSelf]
      },
      {
        id: 'contracts_sent',
        title: 'Contractos Enviados',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/contracts/sent',
        permission: [Permission.LeadList, Permission.LeadListSelf]
      },
      {
        id: 'sellers',
        title: 'Consultores',
        type: 'basic',
        icon: 'heroicons_outline:trophy',
        link: '/sellers/list',
        permission: [Permission.SellerList]
      },
      {
        id: 'experimetal-class-list',
        title: 'Aulas experimentais',
        type: 'basic',
        icon: 'heroicons_outline:computer-desktop',
        link: '/leads/experimetal-classes',
        permission: [Permission.ExperimentalClassListSelf, Permission.ExperimentalClassList]
      },
      {
        id: 'tags',
        title: 'Tags',
        type: 'basic',
        icon: 'heroicons_outline:tag',
        link: '/leads/tags',
        permission: [Permission.TagList]
      },
      {
        id: 'internal_campaign',
        title: 'Campanha Interna',
        type: 'basic',
        icon: 'heroicons_outline:presentation-chart-line',
        link: '/internal_campaign/list',
        permission: [Permission.InternalCampaignViewScreen],
      },
    ]
  },

  {
    id: 'instructors',
    title: "Professores",
    type: 'group',
    permission: [Permission.InstructorList, Permission.InstructorListSquad, Permission.InstructorHoursAcceptSelf, Permission.InstructorHoursEditSelf, Permission.SquadList],
    children: [
      {
        id: 'instructors-list',
        title: 'Professores',
        type: 'basic',
        icon: 'heroicons_outline:identification',
        link: '/instructors/list',
        permission: [Permission.InstructorList, Permission.InstructorListSquad],

      },
      {
        id: 'helpdesk',
        title: 'Ajuda',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: 'http://help.aliancaamerica.com.br',
        externalLink: true,
        target: '_blank',
        permission: [Permission.InstructorHoursAcceptSelf],
      },
      {
        id: 'squads-nav',
        title: 'Squads',
        type: 'basic',
        icon: 'heroicons_outline:rectangle-group',
        link: '/squads/list',
        permission: [Permission.SquadList],
      }
    ]
  },

  {
    id: 'students-nav',
    title: "Alunos",
    type: 'group',
    permission: [Permission.StudentList, Permission.StudentListSelf, Permission.ClassList, Permission.ClassListSelf, Permission.ClassInstructorDistribuition, Permission.DocumentList],
    children: [
      {
        id: 'students-nav',
        title: 'Alunos',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/students/list',
        permission: [Permission.StudentList, Permission.StudentListSelf],
      },
      {
        id: 'classes-nav',
        title: 'Aulas',
        type: 'basic',
        icon: 'heroicons_outline:computer-desktop',
        link: '/classes/list',
        permission: [Permission.ClassList, Permission.ClassListSelf],
      },
      {
        id: 'distribuition',
        title: 'Distribuição de Alunos',
        type: 'basic',
        icon: 'feather:user-plus',
        link: '/classes/instructors-assignment',
        permission: [Permission.ClassInstructorDistribuition],
      },
      {
        id: 'documents-list',
        title: 'Materiais',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document',
        link: '/documents/list',
        permission: [Permission.DocumentList],
      }
    ]
  },

  {
    id: 'financial',
    title: 'Financeiro',
    type: 'group',
    permission: [Permission.InvoiceList, Permission.InvoiceListSelf, Permission.ContractList, Permission.ContractListSelf, Permission.StudentRequestList, Permission.StudentRequestListSelf],
    children: [
      {
        id: 'dashboard-financial',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:presentation-chart-line',
        link: '/start/financial',
        permission: [Permission.DashboardViewMenu],

      },
      {
        id: 'contracts-distribuition',
        title: 'Distribuições',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: '/financials/contract/distribuition',
        permission: [Permission.ContractList, Permission.ContractListSelf],

      },
      {
        id: 'contracts-nav',
        title: 'Contratos',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: '/financials/contract/list',
        permission: [Permission.ContractList, Permission.ContractListSelf],

      },
      {
        id: 'contract_model',
        title: 'Modelos de Contratos',
        type: 'basic',
        icon: 'heroicons_outline:document-duplicate',
        link: '/contracts/list',
        permission: [Permission.ContractModelList],

      },
      {
        id: 'contract_package',
        title: 'Pacotes de Contratos',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/contract-packages/list',
        permission: [Permission.ContractPackageViewScreen],

      },
      {
        id: 'payments-nav',
        title: 'Faturas',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/financials/invoice/list',
        permission: [Permission.InvoiceList, Permission.InvoiceListSelf],
      },
      {
        id: 'students-requests-kanban',
        title: 'Cancelamento',
        type: 'basic',
        icon: 'heroicons_outline:x-circle',
        link: '/students-requests',
        permission: [Permission.StudentRequestList, Permission.StudentRequestListSelf],
      },
      {
        id: 'tags',
        title: 'Tags',
        type: 'basic',
        icon: 'heroicons_outline:tag',
        link: '/financials/invoice/tags',
        permission: [Permission.InvoiceTagList],
      }
    ]
  },

  {
    id: 'advertisement-nav',
    title: 'Anúncios',
    type: 'group',
    permission: [Permission.AdvertisementList],
    children: [
      {
        id: 'advertisement-list',
        title: 'Anúncios',
        type: 'basic',
        icon: 'mat_outline:ads_click',
        link: '/advertisement'
      },
    ]
  },
  {
    id: 'users-nav',
    title: 'Usuários',
    type: 'group',
    icon: 'heroicons_outline:users',
    permission: [Permission.AccessGroupList, Permission.UserList, Permission.DepartmentList],
    children: [
      {
        id: 'users-list',
        title: 'Usuários',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/users/list',
        permission: [Permission.UserList],
      },
      {
        id: 'access-groups',
        title: 'Grupos de Acesso',
        type: 'basic',
        icon: 'mat_outline:lock_open',
        link: '/users/access-group/list',
        permission: [Permission.AccessGroupList],
      },
      {
        id: 'departments',
        title: 'Setores',
        type: 'basic',
        icon: 'heroicons_outline:building-office-2',
        link: '/departments/list',
        permission: [Permission.DepartmentList],
      }
    ],
  },

  {
    id: 'tickets',
    title: 'Tickets',
    type: 'group',
    icon: 'heroicons_outline:users',
    permission: [Permission.TicketList, Permission.TicketListAccessGroup, Permission.TicketListSelf],
    children: [
      {
        id: 'tickets-list',
        title: 'Tickets',
        type: 'basic',
        icon: 'heroicons_outline:ticket',
        link: '/tickets/kanban',
        permission: [Permission.TicketList, Permission.TicketListAccessGroup, Permission.TicketListSelf],
      }
    ],
  },
  {
    id: 'notifications',
    title: 'Configurações',
    type: 'group',
    icon: 'heroicons_outline:users',
    permission: [Permission.NotificationList],
    children: [
      {
        id: 'notifications-list',
        title: 'Notificações',
        type: 'basic',
        icon: 'heroicons_outline:bell',
        link: '/notifications/list',
        permission: [Permission.NotificationList],
      },
      {
        id: 'countries',
        title: 'Países',
        type: 'basic',
        icon: 'heroicons_outline:map',
        link: '/countries',
        permission: [Permission.CountryList],
      },
      {
        id: 'companies',
        title: 'Empresas',
        type: 'basic',
        icon: 'heroicons_outline:building-office-2',
        link: '/companies',
        permission: [Permission.CompanyList],
      },
      {
        id: 'bulk',
        title: 'Notificações em Massa',
        type: 'basic',
        icon: 'heroicons_outline:building-office-2',
        link: '/notifications/notification-bulk',
        permission: [Permission.CompanyList],
      }
    ],
  },
  {
    id: 'reports',
    title: 'Relatórios',
    type: 'group',
    icon: 'heroicons_outline:chart-bar',
    permission: [Permission.Report],
    children: [
      {
        id: 'reports-list',
        title: 'Relatórios',
        type: 'basic',
        icon: 'heroicons_outline:list-bullet',
        link: '/reports',
        permission: [Permission.Report],
      }
    ],
  },
  {
    id: 'temp',
    title: 'Temporário',
    type: 'group',
    icon: 'heroicons_outline:chart-bar',
    permission: [Permission.TempEditImportedStudentClassOptions],
    children: [
      {
        id: 'hour-list',
        title: 'Editar Horários',
        type: 'basic',
        icon: 'heroicons_outline:cog-8-tooth',
        link: '/students-class-options',
        permission: [Permission.TempEditImportedStudentClassOptions],
      }
    ],
  },
];
