import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AccessGroupClient,
  AccessGroupResponse,
  AddressClient,
  AddressResponse,
  CountryClient,
  CountryResponse,
  DepartmentClient,
  DepartmentResponse,
  Gender,
  Status,
  TimeClient,
  UserType,
} from '../../../../../../api-client';
import { FormGroup } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { reversefilter } from 'app/shared/utils/date.filter';
import {
  documentTypes,
  getVatNumberMask,
} from '../../../../shared/utils/document-type-helper';
import { CountryOption } from 'app/shared/models/country-option';
import { IntlTelInputService } from '../../../../shared/services/intl-tell-input/intl-tel-input.service';
import { MatSelectChange } from '@angular/material/select';
import { FormValidationHelper } from 'app/shared/utils/form-validator';

@Component({
  selector: 'app-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  styleUrls: ['./user-edit-form.component.scss'],
})
export class UserEditFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() imageUploader: boolean = true;
  @Input() loginEdit: boolean = false;
  @Input() loginMask: string = 'SS000000';
  @Input() cols: 1 | 2 = 2;
  @Input() showDepartment: boolean = false;

  reverseFilter = reversefilter;

  documentTypes = documentTypes;

  gender = Gender;

  userType = UserType;

  countries: CountryResponse[] = [];

  profilePicture: string;
  profilePictureFile: File;

  cepSubscription: Subscription;

  timezoneList: any[];

  constructor(
    private readonly countryClient: CountryClient,
    private readonly addressClient: AddressClient,
    private readonly departmentClient: DepartmentClient,
    private readonly accessGroupsClient: AccessGroupClient,
    private readonly timeClient: TimeClient,
    private intlTelInputService: IntlTelInputService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.cep();
    this.listCountries();

    if (this.showDepartment) {
      this.listAccessGroups();
      this.listDepartments();
    }

    this.timeClient.timezones().subscribe({
      next: (response) => {
        this.timezoneList = response;
      },
    });

    this.updateDefaultCountryCodeAndPlaceHolder();
    FormValidationHelper.triggerValidation(this.form);
  }

  ngOnDestroy(): void {
    this.cepSubscription.unsubscribe();
  }

  cep() {
    this.cepSubscription = this.form
      ?.get('postalCode')
      .valueChanges.pipe(distinctUntilChanged(), debounceTime(600))
      .subscribe({
        next: (value: string) => {
          if (value) {
            const newCep: string = value
              .replaceAll('-', '')
              .replaceAll('.', '')
              .trim();

            if (newCep.length == 8) {
              this.addressClient.getAddressByCep(value).subscribe({
                next: (response) => {
                  if (response.postalCode) {
                    if (response.city)
                      this.form.patchValue(<AddressResponse>{
                        city: response.city,
                      });
                    if (response.complement)
                      this.form.patchValue(<AddressResponse>{
                        complement: response.complement,
                      });
                    if (response.district)
                      this.form.patchValue(<AddressResponse>{
                        district: response.district,
                      });
                    if (response.number)
                      this.form.patchValue(<AddressResponse>{
                        number: response.number,
                      });
                    if (response.state)
                      this.form.patchValue(<AddressResponse>{
                        state: response.state,
                      });
                    if (response.street)
                      this.form.patchValue(<AddressResponse>{
                        street: response.street,
                      });
                  }
                },
              });
            }
          }
        },
      });
  }

  listCountries() {
    this.countryClient.list(null).subscribe({
      next: (response) => {
        this.countries = response;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  profilePictureSelected(file: File) {
    this.profilePictureFile = file;

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      this.profilePicture = reader.result.toString();
    };
  }

  get saveActive(): boolean {
    return this.form.valid && !!this.profilePicture;
  }

  getVatNumberMask(): string {
    let documentType = this.form?.get('documentType').value;

    if (documentType) {
      return getVatNumberMask(documentType);
    }
  }

  getPostalCodeMask(): string {
    let nationalityId = this.form?.get('countryId').value;

    if (nationalityId) {
      return this.countries?.filter((x) => x.id == nationalityId)[0]
        ?.postalCodeMask;
    }
  }

  departments: DepartmentResponse[];

  listDepartments() {
    this.departmentClient.list(null, Status.Active, 50, 1).subscribe({
      next: (response) => {
        this.departments = response.items;
      },
    });
  }

  accessGroups: AccessGroupResponse[];

  listAccessGroups() {
    this.accessGroupsClient.list(null, Status.Active, 50, 1).subscribe({
      next: (response) => {
        this.accessGroups = response.items;
      },
    });
  }

  /**
   * Phone Number Flags Update
   */
  countryOptions: CountryOption[] = [];
  primaryPhoneNumberPlaceholder: string;
  primaryPhoneNumberMask: string;
  secondaryPhoneNumberPlaceholder: string;
  secondaryPhoneNumberMask: string;
  defaultPhoneSelectValue: string;

  async onChangePrimaryPlaceholder(iso2: string): Promise<void> {
    const { placeholder, mask } =
      this.intlTelInputService.updatePlaceholderAndMask(iso2);
    this.primaryPhoneNumberPlaceholder = placeholder;
    this.primaryPhoneNumberMask = mask;
    // Manual change detection with detectChanges() is used here to update the view after asynchronous operations,
    // preventing "ExpressionChangedAfterItHasBeenCheckedError" by ensuring view consistency with the model state.
    this.changeDetectorRef.detectChanges();
  }

  async onChangeSecondaryPlaceholder(iso2: string): Promise<void> {
    const { placeholder, mask } =
      this.intlTelInputService.updatePlaceholderAndMask(iso2);
    this.secondaryPhoneNumberPlaceholder = placeholder;
    this.secondaryPhoneNumberMask = mask;
    // Manual change detection with detectChanges() is used here to update the view after asynchronous operations,
    // preventing "ExpressionChangedAfterItHasBeenCheckedError" by ensuring view consistency with the model state.
    this.changeDetectorRef.detectChanges();
  }

  onPrimaryCountryChange(event: MatSelectChange): void {
    const iso2 = this.intlTelInputService.getIso2Code(event.value);
    this.onChangePrimaryPlaceholder(iso2);
  }

  onSecondaryCountryChange(event: MatSelectChange): void {
    const iso2 = this.intlTelInputService.getIso2Code(event.value);
    this.onChangeSecondaryPlaceholder(iso2);
  }

  updateDefaultCountryCodeAndPlaceHolder(): void {
    this.intlTelInputService.initializationStatus$.subscribe(
      (isInitialized) => {
        if (isInitialized) {
          this.countryOptions = this.intlTelInputService.getCountryOptions();
          if (this.countryOptions.length > 0) {
            const defaultCountry = this.countryOptions[0].value;

            const primaryPhoneCountryCode = this.form.get(
              'primaryPhoneCountryCode',
            );
            if (!primaryPhoneCountryCode.value) {
              this.onChangePrimaryPlaceholder(defaultCountry.iso2);
              primaryPhoneCountryCode.setValue(`+${defaultCountry.dialCode}`);
            } else {
              const selectedIso = this.intlTelInputService.getIso2Code(
                primaryPhoneCountryCode.value,
              );
              this.onChangePrimaryPlaceholder(selectedIso);
            }

            const secondaryPhoneCountryCode = this.form.get(
              'secondaryPhoneCountryCode',
            );
            if (!secondaryPhoneCountryCode.value) {
              this.onChangeSecondaryPlaceholder(defaultCountry.iso2);
              secondaryPhoneCountryCode.setValue(`+${defaultCountry.dialCode}`);
            } else {
              const selectedIso = this.intlTelInputService.getIso2Code(
                secondaryPhoneCountryCode.value,
              );
              this.onChangeSecondaryPlaceholder(selectedIso);
            }
          }
        }
      },
    );
  }
}
